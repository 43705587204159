.close {
    position: absolute;
    top: 10px;
    right: 11px;
    z-index: 200;
    border: 0;
    background-color: transparent;
    font-size: 28px;
    font-weight: $close-font-weight;
    line-height: 1;
    color: $close-color;
    text-shadow: $close-text-shadow;
    opacity: .5;
    cursor: pointer;
    transition: all .25s;

    @include hover-focus {
        color: $close-color;
        text-decoration: none;
        opacity: .75;
        outline: 0;
        transition: all .25s;
    }
}
