h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    @include font-alt-bold();

    font-weight: $bold;
    line-height: $headings-line-height;
}

h1 {
    padding-bottom: 4px;
    margin-bottom: 1.25rem;
    font-size: $h1-font-size;
    color: $blue;
}

h2 {
    font-size: $h2-font-size;
}



@include media-breakpoint-up(lg) {

    h1 {
        font-size: $h1-font-size-desktop;
    }

}
