header {
    padding-top: 1.8rem;
    background-color: $blue;
    // overflow: hidden;

    > .container {
        padding-bottom: .8rem;
    }
}

.skyline {
    padding-left: 2.8rem;
}

@include media-breakpoint-down(md) {

}

@include media-breakpoint-down(lg) {

}

@include media-breakpoint-up(sm) {

}

@include media-breakpoint-up(lg) {

}

@include media-breakpoint-up(xl) {

}
