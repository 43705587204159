html {
    min-height: 100%;
}

.container--main.is-two-col {

}

.content {
    padding-top: 2rem;
    padding-bottom: 4rem;
    background-color: $white;

    > .home-above-fold,
    > .slider__container {
        margin-top: -2rem;
    }
}

.slider__container {
    margin-bottom: 3rem;
}


@include media-breakpoint-down(sm) {
    .container--main {
        padding-right: 0;
        padding-left: 0;
    }
}

@include media-breakpoint-up(sm) {

}

@include media-breakpoint-up(md) {

}

@include media-breakpoint-up(lg) {
    body {
        display: flex;
        height: 100vh;
        flex-direction: column;
    }
    .container--main {
        flex: 1;
    }
    .content {
        height: 100%;
    }
}

@include media-breakpoint-up(xl) {

}
