.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.hidden {
    display: none;
}

.align-items-center {
    align-items: center;
}

.is-strike-through {
    text-decoration: line-through;
}

.text-hide {
  @include text-hide();
}
