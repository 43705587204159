.table thead th {
	border-top: 0;
}

.kurse .table tbody td:first-child {
	@include font-alt-bold();
}

.kurse .table th h2 {
	margin-bottom: 0;
}
