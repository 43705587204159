.svgIcon {
    display: inline-block;

    svg {
        height: auto;
    }
}

.svgIcon--menu_burger_button {
    svg {
        width: 26px;
        height: 21px;
    }
}

.svgIcon--menu_white_arrow_mobile {
    svg {
        position: relative;
        top: 5px;
        width: 30px;
    }
}

.svgIcon--search_icon {
    svg {
        width: 24px;
        height: 24px;
    }
}

.svgIcon--tickets_mainpage_btn_notext {
    svg {
        width: 134px;
        height: 52px;
    }
}

.svgIcon--ticket_btn_big_bg {
    svg {
        width: 149px;
        height: 36px;
    }
}

.svgIcon--flag_blue_vorderhaus {
    svg {
        width: 270px;
        height: 87px;
    }
}

.svgIcon--flag_green_trailer {
    svg {
        width: 310px;
        // height: 87px;
    }
}

.svgIcon--checkbox,
.svgIcon--checkbox_active {
    svg {
        width: 20px;
    }
}

.svgIcon--reset_icon {
    position: relative;
    top: 6px;
    right: 2px;

    svg {
        width: 20px;
    }
}

.svgIcon--message_btn_notext_desktop {
    svg {
        width: 287px;
        height: 51px;
    }
}

.svgIcon--message_btn_notext_mobile {
    svg {
        width: 282px;
        height: 55px;
    }
}

.svgIcon--message_btn_newsletter_notext_alternative_v002 {
    svg {
        width: 308px;
        height: 51px;
    }
}

.svgIcon--message_btn_newsletter_notext_alternative_v002_kids {
    svg {
        width: 360px;
        height: 51px;
    }
}

.svgIcon--message_btn_newsletter_mobile {
    svg {
        width: 287px;
        height: 55px;
    }
}

.svgIcon--form_submit_btn {
    svg {
        width: 165px;
    }
}

.svgIcon--donate_icon,
.svgIcon--member_icon {
    svg {
        width: 68px;
        height: 68px;
    }
}

.svgIcon--nav_home {
    svg {
        width: 95px;
    }
}

.svgIcon--nav_ausser-haus {
    svg {
        width: 79px;
    }
}

.svgIcon--nav_kinderprogramm {
    svg {
        width: 114px;
    }
}

.svgIcon--nav_kontakt-anfahrt {
    svg {
        width: 129px;
    }
}

.svgIcon--nav_programm {
    svg {
        width: 188px;
    }
}

.svgIcon--nav_ausser-reihe {
    svg {
        width: 78px;
    }
}

.svgIcon--nav_ticketservice {
    svg {
        width: 98px;
    }
}

.svgIcon--logo_fabrik {
    svg {
        width: 280px;

        @include media-breakpoint-up(md) {
            // width: 500px;
        }
    }
}

.svgIcon--logo_skyline svg {
    position: relative;
    top: 23px;
    width: 282px;
    @include media-breakpoint-up(md) {
    }
}

.svgIcon--footer_icon_facebook {
    svg {
        width: 32px;
        height: 32px;
    }
}

.svgIcon--footer_icon_instagram {
    svg {
        width: 32px;
        height: 32px;
    }
}

.svgIcon--footer_icon_twitter {
    svg {
        width: 32px;
        height: 32px;
    }
}

.svgIcon--calender_number_frame {
    svg {
        width: 22px;
        height: 22px;
    }
}
