.menu {
	margin-top: 2rem;

	.entry {
		.wrapper {
			padding-bottom: 8px;
			margin-bottom: 1rem;
			border-bottom: 1px solid black;
		}

		.subheading {
			margin-bottom: 0;
		}

		.publishedAt {
			color: $gray;
			font-size: 13px;
		}

		+ .entry {
			margin-top: 2rem;
		}
	}
}

.mittagstisch .preise {
	padding-top: 8px;
	margin-top: 3rem;
	border-top: 1px solid black;
}

.mittagstisch .body {
	padding-top: 8px;
	border-top: 1px solid black;
}
