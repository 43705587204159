.mainnav {
    position: relative;
    background: linear-gradient(-4deg, #97b500 0%,#97b500 50%,#b1c700 50%,#b1c700 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

    @include media-breakpoint-down(lg) {
        height: 44px;
    }
}

.mainnav__wrapper {
    position: relative;
}

.mainnav__menu {
    display: flex;
    margin-bottom: 0;

    > li {
        position: relative;

        &:first-child {
            margin-left: -14px;
        }

        &.has-children {
            &:hover a + .nav__dropdown {
                display: block;
            }
        }

        + li {
            margin-left: .7rem;
        }

        > a {
            display: block;
            color: $white;
            padding: 16px 14px 13px;
        }
    }
}

.nav__dropdown {
    display: none;
    position: absolute;
    top: 100%;
    width: 230px;
    z-index: 40;
    padding: 0;
    list-style: none;
    margin-bottom: 0;
    padding-top: 4px;
    padding-bottom: 4px;
    background-color: $white;

    > li {
        > a {
            display: block;
            padding: 6px 10px 6px 14px;
            line-height: 18px;
            text-align: left;
            text-decoration: none;
            color: inherit;
            opacity: .65;
            color: $gray-darker;
        }

        &.is-active a {
            opacity: 1;
            color: $primary;
            //font-weight: bold;
        }

        &:hover a {
            color: $white;
            background-color: $primary;
            opacity: 1;
        }
    }
}

.burger {
    display: none;
    position: absolute;
    top: 0;
    left: 15px;
    padding: 10px;
}


@include media-breakpoint-down(lg) {

    .burger {
        display: block;
    }

    .mainnav {
        > .container {
            margin: 0;
            padding: 0;
        }
    }

    .mainnav__menu {
        display: none;
        position: absolute;
        top: 43px;
        left: 0;
        width: 100%;
        min-height: 262px;
        padding-top: 1rem;
        padding-bottom: 1.25rem;
        padding-left: 10px;
        z-index: 1080;
        background-color: $primary;
        list-style: none;

        li {
            color: $white;

            &:first-child {
                margin-left: 0;
            }

            + li {
                margin-left: 0;
            }

            a {
                padding: 4px;
                line-height: 1.3;
            }

            + li {
                margin-top: 5px;
            }
        }

        span {
            color: $white;
            font-size: 18px;
        }
    }

    .nav__dropdown {
        top: -4px;
        right: 0;
        width: 190px;
        padding-right: 10px;
        background-color: transparent;

        > li {
            > a {
                color: $white;
            }

            &.is-active {
                position: relative;
                &::before {
                    content: "";
                    position: absolute;
                    left: -12px;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    height: 14px;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 6px 0 6px 8px;
                    border-color: transparent transparent transparent $white;
                }

                > a {
                    color: $white;
                }
            }
        }
    }

    .mainnav__wrapper {
        &.is-extended {
            .svgIcon--menu_burger_button {
                display: none;
            }

            .svgIcon--menu_white_arrow_mobile {
                display: block;
            }

            .mainnav__menu {
                display: block;
            }
        }

        .svgIcon--menu_white_arrow_mobile {
            display: none;
        }
    }

}




@include media-breakpoint-up(lg) {

    .burger {
        left: 10px;
    }

}


@include media-breakpoint-up(xl) {

    .mainnav__menu {
        list-style: none;
        padding-left: 0;

        li {
            opacity: .65;
            transition: opacity .25s ease-in;

            svg {
                height: 45px;
            }

            &:hover {
                opacity: 1;
                transition: opacity .25s ease-out;
            }

            &.is-active {
                opacity: 1;
                position: relative;
            }
        }
    }

}
