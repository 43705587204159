.cms {
    padding-right: 2.5rem;
    padding-left: 2.5rem;

    @include media-breakpoint-up(lg) {
        .inner {
            .default.is-two-col & {
                columns: 2;
                column-gap: 3rem;
            }
        }
    }

    p + h2 {
        margin-top: 3rem;
    }
}

.color-red {
    color: $red;
}

.archiv {

    h1 {
        margin-bottom: 1rem;
    }

    .kalender__entries {
        margin-top: 4rem;
    }
}


.sponsoren {

    @include media-breakpoint-up(lg) {

        .sponsoren__text {
            column-count: 2;
            column-gap: 40px;
        }
    }

    .sponsoren__images {
        margin-top: 2rem;
        display: flex;
        flex-flow: wrap;
        flex-direction: row;
        align-items: center;
        // justify-content: space-evenly;
    }

    .sponsoren__image {
        margin-right: 20px;

        img {
            max-width: 100%;
        }
    }

}
