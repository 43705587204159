.news {
	margin-top: 2rem;

	.item {
		.wrapper {
			padding-bottom: 8px;
			margin-bottom: 1rem;
			border-bottom: 1px solid black;
		}

		h2 {
			margin-bottom: 0;
		}

		.publishedAt {
			color: $gray;
			font-size: 13px;
		}

		+ .item {
			margin-top: 4rem;
		}
	}
}
