.overview__item {
    padding-right: 2rem;
    padding-left: 2rem;
    margin-bottom: 4rem;
}

.overview__itemHeading {
    padding: 6px 10px 6px 12px;
    margin-bottom: 4px;
    overflow: hidden;
    background-color: $eventTitleBGColor;

    a {
        display: block;
        font-size: 24px;
        color: $white !important;
        text-transform: uppercase;
    }
}

.overview__itemHeadingMoreInfo {
    display: none;
}

.overview__itemInfo {
    color: $white;
}

.overview__itemWrapper {
    .slider__item {
        position: relative;

        a {
            display: block;
        }

        img {
            filter: grayscale(70%);
            object-fit: contain;
            transition: filter .3s;
        }

        &:hover img {
            filter: grayscale(0);
            transition: filter .3s;
        }
    }
}

.overview__itemTitleWrapper,
.overview__itemProgrammWrapper,
.overview__itemMetaWrapper {
    display: block;
    line-height: 1;
}

.overview__itemTitle {
    padding-bottom: 5px;
    margin-bottom: 0;
    font-size: 22px;

    a {
        color: $white;
    }
}

.overview__itemTitle,
.overview__itemProgramm {
    background-color: $eventArtistBGColor;
}

.overview__itemTitle,
.overview__itemProgramm,
.overview__itemMeta {
    display: block;
    padding-top: 4px;
    padding-right: 10px;
    padding-left: 8px;
}

.overview__itemProgramm {
    position: relative;
    top: -3px;
    padding-top: 0;
    padding-bottom: 4px;
    margin-bottom: -3px;
    // @include font-alt(20px);
}

.overview__itemMeta {
    position: relative;
    z-index: 30;
    padding-bottom: 4px;
    // @include font-alt-bold(20px);
}

.overview__itemTicketLink {
    opacity: 0;
    position: absolute;
    right: 18px;
    top: 43px;
    z-index: -1;
    transition: opacity .5s;

    &.has-ticketlink.is-active {
        opacity: 1;
        z-index: 30;
        transition: opacity .5s;
    }
}


@include media-breakpoint-down(md) {

    .overview__item {
        max-width: 500px;
        margin-right: auto;
        margin-bottom: 4rem;
        margin-left: auto;
    }

    .overview__itemMeta {
        padding-top: 6px;
        padding-bottom: 6px;
    }

}

@include media-breakpoint-up(sm) {

    .overview__itemWrapper {
        .slider__item {
            a {
                padding-bottom: 65%;
            }

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }

    .overview__itemInfo {
        position: absolute;
        bottom: 23px;
        right: 16px;
        left: 16px;
        z-index: 25;
    }

    .overview__itemHeading {
        position: relative;

        &::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            z-index: 20;
            border-style: solid;
            border-width: 0 37px 37px 0;
            border-color: transparent $white transparent transparent;
        }

        &:hover .overview__itemHeadingMoreInfo {
            right: 0;
            transition: right .25s ease-in;
        }
    }

    .overview__itemHeadingMoreInfo {
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        bottom: 0;
        right: -104px;
        height: 100%;
        padding-right: 29px;
        margin: auto;
        z-index: 10;
        // @include font-alt-bold(20px);
        text-transform: uppercase;
        color: $white;
        transition: right .25s ease-out;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: -37px;
            border-style: solid;
            border-width: 0 37px 37px 0;
            // border-color: transparent var(--primary-color) transparent transparent;
        }

        &::after {
            content: "";
            position: absolute;
            top: 0;
            right: -4px;
            border-style: solid;
            border-width: 0 37px 37px 0;
            border-color: transparent $white transparent transparent;
        }

        span {
            padding-left: 6px;
        }
    }

    .overview__itemTitle,
    .overview__itemProgramm,
    .overview__itemMeta {
        display: inline-block;
    }

    .overview__itemTicketLink {
        top: auto;
        bottom: -26px;
    }

}
