 @mixin clearfix() {
    &::after {
        content: ".";
        display: block;
        height: 0;
        clear: both;
        visibility: hidden;
    }
 }

// %font-alt-medium {
//     letter-spacing: .8px;
// }

// %font-alt-bold {
//     letter-spacing: .8px;
// }

@mixin font-alt-regular($fontSize: inherit) {
    // @extend %font-alt-medium;
    font-family: $font-family-alt-regular;
    font-size: $fontSize;
    font-weight: 400;
}

@mixin font-alt-medium($fontSize: inherit) {
    // @extend %font-alt-medium;
    font-family: $font-family-alt-medium;
    font-size: $fontSize;
    font-weight: 500;
}

@mixin font-alt-semibold($fontSize: inherit) {
    // @extend %font-alt-bold;
    font-family: $font-family-alt-semibold;
    font-size: $fontSize;
    font-weight: 600;
}

@mixin font-alt-bold($fontSize: inherit) {
    // @extend %font-alt-bold;
    font-family: $font-family-alt-bold;
    font-size: $fontSize;
    font-weight: 700;
}
