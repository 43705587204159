// Font
$font-family-base: 'Open Sans', sans-serif;

$font-family-alt-medium: 'Raleway', sans-serif;
$font-family-alt-regular: 'Raleway', sans-serif;
$font-family-alt-semibold: 'Raleway', sans-serif;
$font-family-alt-bold: 'Raleway', sans-serif;

$font-size-base: 1rem;
// $line-height-base: 1.4;
$light: 300;
$normal: 400;
$bold: 600;


// Basic colors
$primary: #B1C700;
$blue: #0067AA;
$blue-light: #469FD1;

$green2: #97B500;
$green3: #ddf2a2;

$white: #FFFFFF;
$black: #000000;
$orange: #F27231;
$red: #e8000b;
$gray: #cccccc;
$gray-light: #EEEEEE;
$gray-darker: #6F6F6F;
$text-color: #333333;
$link-color: $primary;

// Breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1120px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1065px
);

// Content
$contentHorizontalGutter: 3rem;

// Headings
$headings-font-weight: $bold;
$h1-font-size: 24px;
$h1-font-size-desktop: 30px;
$h2-font-size: 24px;
$letterSpacingHeadings: -0.5px;

// Nav
$navHeight: 50px;
$navHeightDesktop: 75px;

// Calendar
$calendarBorderColor: #BFBFBF;
$calendarNoTagColor: #EEEEEE;
$calendarFreeTagColor: #CFCFCF;

// Nav
$navTextColor: #F0F0F0;
$navNotActiveOpacity: .5;
$navBracketOpacity: .3;

// Body & Footer
$body-bg: #F0F0F0;
$footerBGColor: #15303D;

// Event grid
$eventTopBorderColor: #212121;
$eventArtistBGColor: #2C2C2C;
$eventTitleBGColor: #2C2C2C;

// Event detail
$eventDetailBorderColor: #525252;

// Calendar
$calendarPrimaryColor: #384d8e;
$calendarBorderColor: #BFBFBF;
$calendarFreeColor: #CFCFCF;
$calendarNoDayColor: #EEEEEE;
$calendarOngoingColor: #828eae;


$modal-inner-padding: 40px 30px 40px;
$modal-content-border-color: transparent;
$modal-header-padding: 15px;
$modal-lg: 800px;
$modal-md: 800px;
$modal-sm: 300px;
