.socials {
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;

    li {
        display: inline-block;

        + li {
            margin-left: 10px;
        }
    }
}



@include media-breakpoint-up(sm) {

    .socials {
        margin-top: 6px;
        text-align: right;

        li + li {
            margin-left: 10px;
        }
    }

}

@include media-breakpoint-up(md) {

    .socials {
        li + li {
            margin-left: 14px;
        }
    }

}

@include media-breakpoint-up(lg) {

    .socials {
        li + li {
            margin-left: 20px;
        }
    }

}
