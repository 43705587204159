/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/open-sans-v15-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
       url('../fonts/open-sans-v15-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/open-sans-v15-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/open-sans-v15-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/open-sans-v15-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/open-sans-v15-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* raleway-regular - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/raleway-v12-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Raleway'), local('Raleway-Regular'),
       url('../fonts/raleway-v12-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/raleway-v12-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/raleway-v12-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/raleway-v12-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/raleway-v12-latin-regular.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-500 - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/raleway-v12-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Raleway Medium'), local('Raleway-Medium'),
       url('../fonts/raleway-v12-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/raleway-v12-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/raleway-v12-latin-500.woff') format('woff'), /* Modern Browsers */
       url('../fonts/raleway-v12-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/raleway-v12-latin-500.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-600 - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/raleway-v12-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Raleway SemiBold'), local('Raleway-SemiBold'),
       url('../fonts/raleway-v12-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/raleway-v12-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/raleway-v12-latin-600.woff') format('woff'), /* Modern Browsers */
       url('../fonts/raleway-v12-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/raleway-v12-latin-600.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-700 - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/raleway-v12-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Raleway Bold'), local('Raleway-Bold'),
       url('../fonts/raleway-v12-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/raleway-v12-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/raleway-v12-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/raleway-v12-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/raleway-v12-latin-700.svg#Raleway') format('svg'); /* Legacy iOS */
}
