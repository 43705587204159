@charset "UTF-8";

$slick-font-path: "./fonts/";
$slick-loader-path: "../images/";
$slick-arrow-color: white;
$slick-dot-size: 6px;
$slick-opacity-default: 0.75;
$slick-opacity-on-hover: 1;
$slick-opacity-not-active: 0.25;

@function slick-image-url($url) {
    @if function-exists(image-url) {
        @return image-url($url);
    }
    @else {
        @return url($slick-loader-path + $url);
    }
}

.slick-slider {
    z-index: 20;
}

.slick-list {
    .slick-loading & {
        background: #fff slick-image-url("ajax-loader.gif") center center no-repeat;
    }
}

.slick-slide {
    &:focus,
    a:focus {
        outline: 0;
    }

    img {
        display: inline;
    }
}

.slick-prev,
.slick-next {
    position: absolute;
    top: 50%;
    width: 26px;
    height: 26px;
    z-index: 30;
    margin-top: -20px;
    fill: $slick-arrow-color;
    cursor: pointer;
    transition: all .2s;

    .slick-initialized & {
        display: block !important;
    }

    &:hover,
    &:focus {
        fill: $white;
        transition: all .2s;
    }
}

.slick-prev {
    left: 8px;

    .overview__item & {
        filter: drop-shadow(0px 3px 1px rgba(0, 0, 0, 0.5));
    }

    .portfoliosMore & {
        left: 24px;
    }
}

.slick-next {
    right: 8px;
    transform: rotate(180deg);

    .overview__item & {
        filter: drop-shadow(0px -3px 1px rgba(0, 0, 0, 0.5));
    }

    .portfoliosMore & {
        right: 24px;
    }
}

.slick-dotted.slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    display: block;
    list-style: none;
    text-align: center;
    padding: 0;
    margin: 10px 0 0;
    width: 100%;

    li {
        position: relative;
        display: inline-block;
        margin: 0 6px;
        padding: 0;
        cursor: pointer;

        button {
            border: 0;
            background: transparent;
            display: block;
            height: $slick-dot-size;
            width: $slick-dot-size;
            outline: none;
            line-height: 0px;
            font-size: 0px;
            color: transparent;
            padding: 0;
            cursor: pointer;

            &:hover,
            &:focus {
                outline: none;

                &:before {
                    opacity: $slick-opacity-on-hover;
                    transition: opacity .2s;
                }
            }

            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                background-color: $gray-dark;
                width: $slick-dot-size;
                height: $slick-dot-size;
                text-align: center;
                border-radius: 50%;
                opacity: $slick-opacity-not-active;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                transition: opacity .2s;
            }
        }

        &.slick-active button::before {
            background-color: $black;
            opacity: $slick-opacity-default;
        }
    }
}

.slider__item:not(:first-child),
.sliderNav__item {
  display: none;

  .slick-initialized & {
    display: block;
  }
}

.slider__item {
    position: relative;

    a,
    a:hover {
        text-decoration: none;
    }
}

.slider__itemImage {
    text-align: center;

    // &.responsively-lazy {
        display: block;
    // }
}
