.kontakt {

    section + section {
        margin-top: 6rem;
    }

    h2,
    h3 {
        letter-spacing: -1px;
    }

    h2 {
        padding-bottom: 2px;
        border-bottom: 1px solid $black;
        @include font-alt-bold(34px);
        text-transform: uppercase;
    }

    h3 {
        margin-bottom: 4px;
        @include font-alt-bold(20px);
        text-transform: uppercase;

        &:not(:first-child) {
            margin-top: 1.5rem;
        }
    }

    .section__meta {
        margin-bottom: 1.25rem;
        font-size: 12px;
        color: $gray;
    }
}

.map {
    margin-bottom: 2rem;

    iframe {
        max-width: 100%;
    }
}

.contact__buttonDesktop,
.contact__buttonMobile {
    display: inline-block;
    margin-top: 1rem;

    &:focus {
        outline: 0;
    }
}

.contact__buttonMobile {
    position: relative;

    span {
        position: absolute;
        top: 15px;
        left: 62px;
        color: $white;
        @include font-alt-bold(18px);
        letter-spacing: $letterSpacingHeadings;
        text-transform: uppercase;
    }
}

.contact__button--newsletter {
    .contact__buttonMobile span {
        left: 53px;
    }
}

.contact__buttonDesktop {
    display: none;
    position: relative;

    .contact__buttonDesktopBefore,
    .contact__buttonDesktopAfter {
        position: absolute;
        top: 14px;
        @include font-alt-bold(18px);
        color: $white;
        letter-spacing: $letterSpacingHeadings;
        text-transform: uppercase;
    }

    .contact__buttonDesktopBefore {
        left: 10px;
    }

    .contact__buttonDesktopAfter {
        right: 34px;
    }

    &--submit {
        display: block;

        .contact__buttonDesktopAfter {
            top: 0;
            bottom: 0;
            right: 40px;
            height: 31px;
            margin: auto;
        }
    }
}

.team {
    margin-top: 4rem;
}

.member {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
    margin-bottom: 6rem;
}

.member__image {
    margin-bottom: 2rem;
}

.member__name {
    margin-bottom: 1rem;
    @include font-alt-bold(16px);
    text-transform: uppercase;
    letter-spacing: $letterSpacingHeadings;
}

.member__text {
    position: relative;
    padding-top: 1rem;
    padding-right: 30px;
    padding-bottom: .5rem;
    padding-left: 30px;
    font-size: 14px;
    text-align: justify;

    &::before,
    &::after {
        position: absolute;
        top: 0;
        height: 100%;
        content: "";
        width: 12px;
        border-style: solid;
        border-color: $black;
    }

    &::before {
        left: 0;
        border-width: 2px 0 2px 2px;
    }

    &::after {
        right: 0;
        border-width: 2px 2px 2px 0;
    }
}



// Form
.uniform__potty {
    position: absolute;
    top: -9999px;
    left: -9999px;
}

.form,
.form--newsletter form {

    .form__field {
        margin-bottom: 24px;
    }

    legend,
    label,
    div[for="email"],
    div[for="lastName"],
    div[for="firstName"] {
        display: block;
        margin-bottom: 6px;
        font-size: 14px;
        font-family: $font-family-base !important; // overriding inline styles set from 1&1 JS
        font-weight: $normal !important; // overriding inline styles set from 1&1 JS
    }

    select {
      display: inline-block;
      width: 100%;
      $select-border-width: ($custom-select-border-width * 2);
      height: 39px;
      padding: $custom-select-padding-y ($custom-select-padding-x + $custom-select-indicator-padding) $custom-select-padding-y $custom-select-padding-x;
      line-height: $custom-select-line-height;
      color: $custom-select-color;
      vertical-align: middle;
      background: $custom-select-bg $custom-select-indicator no-repeat right $custom-select-padding-x center;
      background-size: $custom-select-bg-size;
      border: $custom-select-border-width solid #c3c3c3;
      @include border-radius($custom-select-border-radius);
      appearance: none;

      &:focus {
        border-color: $custom-select-focus-border-color;
        outline: none;
        @include box-shadow($custom-select-focus-box-shadow);

        &::-ms-value {
          // For visual consistency with other platforms/browsers,
          // supress the default white text on blue background highlight given to
          // the selected option text when the (still closed) <select> receives focus
          // in IE and (under certain conditions) Edge.
          // See https://github.com/twbs/bootstrap/issues/19398.
          color: $input-color;
          background-color: $input-bg;
        }
      }

      // Hides the default caret in IE11
      &::-ms-expand {
        opacity: 0;
      }
    }

    input,
    textarea {
        width: 100%;
        padding: 8px 10px;
        border: 1px solid #c3c3c3;
        border-radius: 4px;
        font-size: 14px;

        &:focus {
            outline: 0;
        }
    }

    button[type="submit"] {
        background-color: transparent;
        border: 0;
        font-size: inherit;
        font-size: 15px;
        cursor: pointer;

        &:focus {
            outline: 0;
        }
    }

    #recaptcha-container {
        height: 78px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .form__message {
        margin-bottom: 10px;

        &:empty {
            display: none;
        }
    }
}

.form--newsletter form button[type="submit"] {
    padding: 10px 20px;
    margin-bottom: 10px !important;
    color: $white !important;
}

.form--newsletter form > div:first-child,
.contact__heading {
    margin-bottom: 30px;
    // font-family: $font-family-alt-bold !important;
    font-size: 24px !important;
    text-transform: uppercase;
}

.newsletter__row > .col-12 {
    display: flex;
    flex-direction: column;

     p {
        flex: 1 0 auto;
    }
}

@include media-breakpoint-up(lg) {

    .contact__buttonMobile {
        display: none;
    }

    .contact__buttonDesktop {
        display: inline-block;
    }

    .map {
        margin-bottom: 0;
    }

}
