.footer {
    padding-top: 1rem;
    padding-bottom: .75rem;
    padding-left: 2.3rem;
    background-color: $footerBGColor;
}

.footer__pages {
    display: flex;
    flex-wrap: wrap;

    a {
        color: #E6E6E6 !important;
    }
}


@include media-breakpoint-down(xs) {

}

@include media-breakpoint-down(sm) {

    .footer {
        // margin-left: -15px;
        // margin-right: -15px;
    }

    .footer__pages {
        a {
            width: 50%;
        }
    }

}

@include media-breakpoint-down(md) {
  

}

@include media-breakpoint-up(md) {


    .footer__pages {
        height: 100%;
        align-items: center;
        // justify-content: space-between;

        a {
            margin-right: 20px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

}
