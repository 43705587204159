html {
    box-sizing: border-box;

    *,
    *:before,
    *:after {
        box-sizing: inherit;
    }
}

a {
    color: $primary;

    &:hover {
        text-decoration: none;
    }
}

img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
    background-color: transparent;
    border: 0;
}

ul {
    list-style: disc;
    padding-left: 40px;
    margin-bottom: 20px;
}

p {
    margin-bottom: 12px;
}

strong {
    @include font-alt-bold();
}

em {
    font-style: italic;
}

figure {
    margin-bottom: 40px;
}
