.home .cms {
    h1 {
        margin-bottom: 1rem;
    }
}

.home .cms.narrow {
    @include media-breakpoint-up(lg) {
        padding-right: 1rem;

    }
}


.home-above-fold {
	position: relative;
}

.homeTeaser {
    margin-top: 1.7rem;
}

.homeTeaserBox {
    a {
        display: block;
        color: inherit;
    }

    .more {
        color: $blue-light;
    }

    .date {
        font-weight: $bold;
    }

    .text {
        display: block;
    }

    .title,
    .img-wrapper,
    .slider-wrapper,
    .slider__item,
    .body {
        display: block;
    }

    .slick-prev,
    .slick-next {
        top: 86px;
    }

    .title {
        position: relative;
        margin-bottom: 0;
        height: 37px;

        .svgIcon {
            display: block;
        }

        svg {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            width: 100%;
        }

        h4 {
            position: absolute;
            top: 9px;
            left: 14px;
            z-index: 20;
            font-size: 19px;
            color: $white;
        }
    }

    img {
        width: 100%;
        height: 164px;
    }

    .body {
        height: 89px;
        overflow: hidden;
        padding: 10px 13px 13px;
        background-color: #E6E6E6;
        font-size: 15px;
        p {
            margin: 0;
        }
    }

    .date {
        @include font-alt-bold();
    }

    @include media-breakpoint-down(lg) {
        margin-bottom: 2rem;
    }
}

.ticker {
	position: absolute;
	bottom: 24px;
	height: 40px;
	z-index: 1044;
	right: 0;
	left: 0;
	width: 100%;
    max-width: 1035px;
    padding-top: 8px;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -24px;
    white-space: nowrap;
    text-align: center;
    color: $white;
    background-color: rgba(0, 0, 0, .5);

    &.invisible {
        visibility: hidden;
    }

    p {
        margin: 0;
        opacity: 0;

        &::after,
        &::before {
            content: "***";
        }

        &::after {
            padding-left: 4px;
        }
        &::before {
            padding-right: 4px;
        }
    }

    &.visible p {
        opacity: 1;
    }
}

.banner-fabrik,
.banner-vh {
    text-align: center;
}

@include media-breakpoint-up(lg) {
    .banner-fabrik {
        position: relative;
        top: -12px;
        left: 18px;
    }

    .banner-vh {
        position: relative;
        top: -13px;
        left: 18px;
    }

    .home .modal-dialog {
        max-width: map-get($container-max-widths, 'xl');
    }
}
